import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { motion as m } from "framer-motion";
import Ratio from 'react-bootstrap/Ratio';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Grid from '../components/Grid'
import { useFetchSeo } from '../../useFetch';
const parse = require('html-react-parser');


const Post = ({posts}) => {
  const [titleHovered, setTitleHovered] = useState(false)
  const handleNextHover = () => setTitleHovered(prevState => !prevState)
  const nextVariant = {
    notHovered: {
      clipPath: "circle(0 at 0 0)",
      transition: {
        //delay: 0.5,
        //type: "spring",
        // stiffness: 400,
        // damping: 40
      }
    },
    hovered: {
      clipPath: `circle(${230 + 60}px at 0 0)`,
      transition: {
        //type: "spring",
        // stiffness: 20,
        // restDelta: 2
      }
    }
  }

  const seo = useFetchSeo( process.env.REACT_APP_SITE_URL + '/wp-json/custom/options/');
  const [next, setNext] = useState('/')
  //const [prev, setPrev] = useState('/')
  const [post, setPost] = useState([]);
  const [title, setTitle] = useState('');
  const [gallery, setGallery] = useState([]);
  const [related, setRelated] = useState([]);
  const location = useParams().slug;

  useEffect(() => {
    if (posts) {
      const p = posts.filter(item => item.slug.split("/")[3] === location);
      const related = posts.filter(item => item.cat.slug === p[0].cat.slug);
      let index = related.findIndex(function(item, i){
        return item.id === p[0].id
      });

      setPost(p[0]);
      setTitle(p[0].title);
      setGallery(p[0].gallery)
      setRelated(related)


      if (index >= (related.length-1)) {
        setNext(related[0])
      } else {
        setNext(related[index+1])
      }

      // if (index === 0) {
      //   setPrev(posts[posts.length-1]?.slug)
      // } else {
      //   setPrev(posts[index-1]?.slug)
      // }

    }
  }, [posts]);

  return (
    <m.div
        className="page single"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
      <Helmet>
        <title>{post?.title + ' | ' + seo?.title}</title>
      </Helmet>
      <Container fluid>
        <Row className="position-relative pt-8 pt-md-0">
          <h1 className="col-6 col-md-3 position-sticky">{parse(title)}</h1>
          <div className="col-6 col-md-3 offset-md-6 text-end position-sticky">
            {/*<Link to={prev} dangerouslySetInnerHTML={{ __html: '&lt;' }}></Link>*/}
            <m.div
              onMouseEnter={handleNextHover}
              onMouseLeave={handleNextHover}
              className="next-link"
            >
              <Link to={next?.slug} className="ms-2 fs-10 ls-2">NEXT<br/>
                <span className="position-absolute d-none d-md-block">
                  <m.span
                  initial="notHovered"
                  animate={titleHovered ? "hovered" : "notHovered"}
                  variants={nextVariant}
                > 
                  {next?.img && (
                    <>
                      <span className="d-block mt-5 fs-20 ls-1 text-uppercase">{next.title}</span>
                      <img src={next.img[0]} className="ms-auto mt-5"/>
                    </>
                  )}
                  </m.span>
                </span>
              </Link>
            </m.div>
          </div>
          {post && gallery && gallery.map((img, index) => {
        
            return (
              <div key={'page' + post.id + index} className={'offset-md-3 mb-5 mb-md-10 col-md-6'}>
                {img && (
                  <m.div initial={{opacity: 0}} transition={{ duration: 0.3 }} whileInView={{ opacity: 1 }} viewport={{ once: true }}>
                    <Ratio aspectRatio={img['height']*100 / img['width']}>
                      <img src={img['url']} alt="" className="img-fit w-100 lazy" onLoad={(e)=> e.target.style.opacity = 1} />
                    </Ratio>
                  </m.div>
                )}
              </div>
            )
          })}
       </Row>
      </Container>
       {related && (
          <>
          <Container fluid>
            <Row className="pt-100">
              <div className="col-12 text-uppercase fs-14 ls-1 mb-10" dangerouslySetInnerHTML={{ __html: post?.cat?.name }}/ >
            </Row>
          </Container>
          <Grid posts={related} />
          </>
        )}
    </m.div>
  );
};
export default Post;