import { Link, NavLink } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Nav } from "./components/Nav";

function Header( { menu } ) {
  const mm = menu;

  return (
    <header>
      <Container fluid>
        <Row>
          <Col xs={4} className="hover-underline d-none d-md-inline-block"><a href="https://www.facebook.com/soporowskaaa" target="_blank" rel="noreferrer" className="fs-10 ls-2 text-uppercase">Facebook</a></Col>
          <Col xs={4} className="text-center position-relative logo-container"><Link to="/" className="logo">ANNA SOPOROWSKA</Link></Col>
          <Col xs={4} className="text-end hover-underline d-none d-md-inline-block"><a href="https://www.instagram.com/soporowskaaa" target="_blank" rel="noreferrer" className="fs-10 ls-2 text-uppercase">Instagram</a></Col>
          <Col xs={4} className="d-inline-block d-md-none"><Nav menu={mm} /></Col>
          {mm ? (
            <nav className="col-12 d-none d-md-block">
              <ul className="d-flex align-items-center hover-underline justify-content-md-center">
                {mm.map(item => (
                  <li key={item.ID}><NavLink activeclassname="is-active" to={item.url}><span dangerouslySetInnerHTML={{ __html: item.title }} /></NavLink></li>
                ))}
              </ul>
            </nav>
          ) : <nav className="mt-10 pt-2 d-none d-md-block" dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />}
        </Row>
      </Container>
    </header>
  );
}

export default Header;
