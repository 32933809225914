import { motion } from "framer-motion";
import { NavLink } from 'react-router-dom';
import { useFetchSeo } from '../../useFetch';

const variantsUl = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 }
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
};

const variantsLi = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 50, velocity: -100 }
    }
  },
  closed: {
    y: 24,
    opacity: 0,
    transition: {
      y: { stiffness: 50 }
    }
  }
};


export const NavInner = ({ menu, toggle }) => {
  const menu_img = useFetchSeo( process.env.REACT_APP_SITE_URL + '/wp-json/custom/options/');

  return (
    <>
    {menu && (
    <motion.ul className="hover-underline" variants={variantsUl}>
      {menu.map(item => (
        <motion.li
          key={item.ID}
          variants={variantsLi}
        ><NavLink onClick={toggle} activeclassname="is-active" to={item.url}><span dangerouslySetInnerHTML={{ __html: item.title }} /></NavLink>
        </motion.li>
      ))}
      <motion.li
        className="pt-8 fs-12"
        variants={variantsLi}><a href="https://www.instagram.com/soporowskaaa">INSTAGRAM</a></motion.li>
      <motion.li
        className="pb-8 fs-12"
        variants={variantsLi}><a href="https://www.facebook.com/soporowskaaa">FACEBOOK</a></motion.li>
      <motion.li
        className="mt-auto mb-0"
        variants={variantsLi}>
        {menu_img?.menu_img && (
          <img src={ menu_img.menu_img } alt="Ania Soporowska"/>
        )}
      </motion.li>
    </motion.ul>
    )}
    </>
  );
}

