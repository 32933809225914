import React from 'react';
import { motion as m } from "framer-motion";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import Ratio from 'react-bootstrap/Ratio';
import { Helmet } from "react-helmet";
import { useFetchSeo } from '../../useFetch';

const About = ({ page }) => {
  const img = page.featured_media;
  const content = page.content;
  const title = page.title.rendered;
  const seo = useFetchSeo( process.env.REACT_APP_SITE_URL + '/wp-json/custom/options/');

  return (
      <m.div
        className="page pt-8 pt-md-0"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Helmet>
          <title>{title + ' | ' + seo?.title}</title>
        </Helmet>
        <Container fluid>
        <Row>
        <Col md={{ span: 3, offset: 2 }} className="mb-5 pb-5 pb-md-0 mb-md-0 sticky">
          {img && (
            <Ratio aspectRatio={img[1] / img[2]}>
              <img src={img[0]} alt="Ania Soporowska" className="w-100 img-fit lazy" onLoad={(e)=> e.target.style.opacity = 1} />
            </Ratio>
            )}
        </Col>
        {content && (
          <Col md={{ span: 3, offset: 1 }} className="paragraph text-center pb-10 hover-rm-underline" dangerouslySetInnerHTML={{ __html: content.rendered }} />
          )}
        </Row>
        </Container>
      </m.div>
    );
};
export default About;