import { useEffect, useState } from 'react';
import { TimelineMax } from "gsap";

export const useFetchSeo = (url) =>  {
  const [data, setData] = useState({title: 'Anna Soporowska', desc : 'Art & Business photography', menu_img: ''});
  useEffect(() => {
    async function loadData() {
      const response = await fetch(url);
      if(!response.ok) {
        return;
      }

      const seo = await response.json();
      setData(seo);
    }

    loadData();
  }, [url]);
  return data;
}

export const useFetch = (url) =>  {
  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    async function loadData() {
      setLoading(true);
      const response = await fetch(url);
      if(!response.ok) {
                // oups! something went wrong
        return;
      }

      const posts = await response.json();
      setData(posts);
      setLoading(false);
    }

    loadData();
  }, [url]);
  return { data, isLoading };
}

export const useFetchData = () => {
  let id, percent = 0;
  const [posts, setPosts] = useState(null);
  const [menu, setMenu] = useState(null);
  const [about, setAbout] = useState(null);
  const [contact, setContact] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    Promise.all([
      fetch(process.env.REACT_APP_SITE_URL + '/wp-json/wp/v2/posts?orderby=menu_order&order=asc'),
      fetch(process.env.REACT_APP_SITE_URL + '/wp-json/custom/menu/39'),
      fetch(process.env.REACT_APP_SITE_URL + '/wp-json/wp/v2/pages/9'),
      fetch(process.env.REACT_APP_SITE_URL + '/wp-json/wp/v2/pages/11'),
      ])
    .then(([p, m, a, c]) => 
      Promise.all([p.json(), m.json(), a.json(), c.json()])
    )
    .then(([p, m, a, c]) => {
      setPosts(p);
      setMenu(m);
      setAbout(a);
      setContact(c);
    });
  }, []);

  function frame(){
    if (document.querySelector('.loader-counter')) {
      if (percent >= 100){
        clearInterval(id)
        return
      }
      else {
        percent+=10;
        document.querySelector('.loader-counter').innerHTML = percent + '%';
        if (percent >= 100) {
          new TimelineMax()
          .to('.loader', {
            duration: 1.5,
            ease: "expo.inOut",
            delay: 0.4,
            height: 0,
            onComplete: function() {
              setLoading(false);
            }
          })
        }
      }
    }
  }
  
  useEffect(() => {
    setInterval(frame, 60);
  }, []);
  return { posts, menu, about, contact, isLoading };
}