import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { motion as m} from "framer-motion";
import { useFetchSeo } from '../../useFetch';
import Grid from '../components/Grid'
const parse = require('html-react-parser');

const Category = () => {
  const cat = useParams().slug;
  const [title, setTitle] = useState('');
  const [posts, setPosts] = useState([]);
  const [hasPosts, setHasPosts] = useState(true);
  const seo = useFetchSeo( process.env.REACT_APP_SITE_URL + '/wp-json/custom/options/');

  useEffect(() => {
    async function loadData() {
      const response = await fetch(process.env.REACT_APP_SITE_URL + '/wp-json/wp/v2/categories');
      if(!response.ok) {
          return;
      }

      const posts = await response.json()
      return posts;
    }
    loadData().then(p => {
     const currentCat = p.filter(item => item.slug === cat);
      if (currentCat[0].posts.length) {
        setTitle(currentCat[0].name);
        setPosts(currentCat[0].posts);
        setHasPosts(true);
      } else {
        setHasPosts(false)
      }
    });
  }, [cat]);

  return (
    <>
      {/* <InitialTransition /> */}
      <m.div
          className="page pt-8 pt-md-0"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Helmet>
            <title>{parse(title) + ' | ' + seo?.title}</title>
          </Helmet>
          {posts && (
            <Grid posts={posts} />
          )}

          {!hasPosts && (
            <div className="text-uppercase fs-14 col-12 text-center paragraph">Brak wpisów</div>
          )}
      </m.div>
    </>
  );
};
export default Category;


export const blackBox = {
  initial: {
    scaleY: 1,
    //bottom: 0,
    originY: 0,
    //clipPath: 'polygon(0 0, 0 0, 0 100%, 0% 100%)', 
  },
  animate: {
    //clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
    //height: 0,
    scaleY: 0,
    originY: 0,
    transition: {
      when: "afterChildren",
      duration: 1,
      ease: [0.87, 0, 0.13, 1],
    },
  },
  exit: {
    //clipPath: 'polygon(100% 0, 100% 0, 100% 100%, 100% 100%)',
    scaleY: 1,
    //bottom: 0,
    //originY: '100%',
    //borderRadius: 50,
    transition: {
      when: "beforeChildren",
      duration: 1,
      ease: [0.87, 0, 0.13, 1],
    },
  }
};

export const textContainer = {
  initial: {
    opacity: 1,
  },
  animate: {
    opacity: 0,
    transition: {
      duration: 0.3,
      when: "afterChildren",
    },
  },
};

export const text = {
  initial: {
    y: 40,
  },
  animate: {
    y: 80,
    transition: {
      duration: 1.5,
      ease: [0.87, 0, 0.13, 1],
    },
  },
};

export const InitialTransition = () => {
  // Scroll user to top to avoid showing the footer
  React.useState(() => {
    typeof windows !== "undefined" && window.scrollTo(0, 0);
  }, []);

  return (
    <m.div
      className="position-fixed z-50 d-flex align-items-center justify-content-center w-100 bg-black overlay"
      initial="initial"
      animate="animate"
      exit="exit"
      variants={blackBox}
    >
      <m.svg variants={textContainer} className="position-absolute z-50 d-flex">
        <pattern
          id="pattern"
          patternUnits="userSpaceOnUse"
          width={750}
          height={800}
          className="text-white"
        >
          <rect className="w-100 h-100 fill-current" style={{ fill: 'white'}} />
          <m.rect
            variants={text}
            className="w-100 h-100 fill-current"
             style={{ fill: 'gray'}}
          />
        </pattern>
        <text
          textAnchor="middle"
          x="50%"
          y="50%"
          style={{ fill: "url(#pattern)", fontSize: '1.5rem', lineHeight: '1.5rem' }}
        >
          ANNA SOPOROWSKA
        </text>
      </m.svg>
    </m.div>
  );
};