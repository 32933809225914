import React, { useEffect } from 'react';
import { Routes, Route, useLocation, useHistory } from 'react-router-dom';
import { motion as m, AnimatePresence } from "framer-motion";

import './sass/style.scss';
import Header from './inc/Header'
import Category from './inc/pages/Category'
import Post from './inc/pages/Post'
import Home from './inc/pages/Home'
import Page from './inc/pages/Page'
import About from './inc/pages/About'
import { useFetchData } from './useFetch'
import { onEnter, onExit } from './helpers'

const App = () => {
  const location = useLocation();
  const fetch = useFetchData();
  const menu = fetch.menu;
  const posts = fetch.posts;
  const about = fetch.about;
  const contact = fetch.contact;
 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    onEnter('header');
    //onEnter('.page');
  }, [fetch.isLoading]);

  return (
    <> 
      <Header menu={menu} />
    {/*<InitialTransition />*/}
      <AnimatePresence mode="wait" onExitComplete={() => window.scrollTo(0, 0)} initial={true}>
        <Routes location={location} key={location.pathname}>
          <Route key="home" path="/" element={<Home />} exact />        
          <Route key="kat" path="/kategoria/:slug" element={<Category/>} />
          <Route path="/kategoria/:slug/:slug" element={<Post posts={posts} />} />
          <Route path="/o-mnie" element={<About page={about} />} />
          <Route path="/kontakt" element={<About page={contact} />} />
          <Route path="*" element={<p className="text-center py-10 my-10">404</p>} />
        </Routes>
      </AnimatePresence>
    </>
  );
};

export default App;