import React from 'react';
import { Link } from 'react-router-dom';
import { motion as m} from "framer-motion";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import Ratio from 'react-bootstrap/Ratio';
const parse = require('html-react-parser');

const Grid = ({ posts }) => {
  return (  
    <ResponsiveMasonry
      className="px-0 mx-3 mx-md-5 overflow-hidden mt-n5 mb-6 mb-md-5"
      columnsCountBreakPoints={{350: 1, 750: 2, 900: 3, 1400: 4}}
    >
      <Masonry>
       {posts.map((post, index) => (
        <Link to={post.url ? post.url : post.slug} key={post.id} className="post-item" >
          {post.img && (
            <m.span whileInView={{ opacity: 1 }}>
              <Ratio aspectRatio={post.img[2]*100 / post.img[1]}>
                <img src={post.img[0]} alt="" className="lazy img-fit w-100" onLoad={(e)=> e.target.style.opacity = 1} />
              </Ratio>
              {post.title && (
                <span className="post-item-hover">{parse(post.title)}</span>
              )}
            </m.span>
          )}
        </Link>
      ))}
      </Masonry>
    </ResponsiveMasonry>
  );
};
export default Grid;