import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { motion as m } from "framer-motion";

import { useFetchSeo } from '../../useFetch';
import Ratio from 'react-bootstrap/Ratio';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
const parse = require('html-react-parser');

const Home = () => {
  const [posts, setPosts] = useState([]);
  const seo = useFetchSeo( process.env.REACT_APP_SITE_URL + '/wp-json/custom/options/');

  useEffect(() => {
    async function loadData() {
      const response = await fetch(process.env.REACT_APP_SITE_URL + '/wp-json/wp/v2/pages/2?acf_format=standard');
      if(!response.ok) {
          return;
      }

      const posts = await response.json()
      return posts;
    }
    loadData().then(p => {
      if (p?.acf?.gallery) {
        setPosts(p.acf.gallery);
      }
    });
  }, []);

  return (
    <>
      <m.div
          className="page"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
        <Helmet>
          <title>{parse(seo?.title) + ' | ' + parse(seo?.desc)}</title>
        </Helmet>

        {posts && (
          <ResponsiveMasonry
            className="px-0 mx-3 mx-md-5 overflow-hidden mt-n5 mb-6 mb-md-5"
            columnsCountBreakPoints={{350: 1, 750: 2, 900: 3, 1400: 4}}
          >
            <Masonry>
             {posts.map((post, index) => ( 
              <div className="post-item d-block" style={{pointerEvents: 'none'}}>

                {post.img && (
                  <m.span className="d-block" whileInView={{ opacity: 1 }}>
                    <Ratio aspectRatio={post.img.height*100 / post.img.width}>
                      <img src={post.img.sizes.large} alt="" className="img-fit w-100 lazy" onLoad={(e)=> e.target.style.opacity = 1} />
                    </Ratio>
                  </m.span>
                )}
              </div>
            ))}
            </Masonry>
          </ResponsiveMasonry> 
        )}

      </m.div>
    </>
  );
};

export default Home;