import { gsap } from "gsap";

export const getRatio = (width, height) => {
	const ratio = (height*100)/width;

	return ratio.toFixed(0);
}

export const onEnter = node => {
  gsap.fromTo(
    node,
    1,
    {
    	opacity: 0,
    },
    {
      delay: 0.3,
      ease: "power3.InOut",
      opacity: 1,
    }
  );
};

export const onExit = node => {
  gsap.to(
    node,
    .5,
    {
      opacity: 0,
      ease: "power3.InOut"
    }
  );
};

export const randomInt = (min, max) => { // min and max included 
  return Math.floor(Math.random() * (max - min + 1) + min)
}